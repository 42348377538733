import React from "react";
import { Nav } from "shards-react";
import Cookie from "js-cookie";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
      adminIlems: Store.getAdminSidebarItems(),
      coordinatorItems: Store.getCoordinatorSidebarItems(),
      userData: {}
    };

    this.onChange = this.onChange.bind(this);
    this.getToken = this.getToken.bind(this);
  }
  getToken = async () => {
    const userData = (await Cookie.get("userData"))
      ? Cookie.get("userData")
      : null;
    var obj = JSON.parse(userData);
    this.setState({
      userData: obj
    });
    console.log(this.state);
  };

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    console.log(this.state);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  componentDidMount() {
    this.getToken();
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    if (this.state.userData.role === "super_admin") {
      const { navItems: items } = this.state;
      return (
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))}
          </Nav>
        </div>
      );
    } else if (this.state.userData.role === "admin") {
      const { adminIlems: items } = this.state;
      return (
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))}
          </Nav>
        </div>
      );
    } else {
      const { coordinatorItems: items } = this.state;
      return (
        <div className="nav-wrapper">
          <Nav className="nav--no-borders flex-column">
            {items.map((item, idx) => (
              <SidebarNavItem key={idx} item={item} />
            ))}
          </Nav>
        </div>
      );
    }
  }
}

export default SidebarNavItems;
