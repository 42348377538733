import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  Alert,
  FormSelect,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddUsers extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);

    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);

    this.state = {};
  }

  handleChange(e) {
    console.log(this.state);
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);
    stateData.role = "coordinator";

    console.log("Sening");
    let response = await axios
      .post(`${URL.BASE_URL}/auth/signup`, stateData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        name: " ",
        email: "",
        hospital: "",
        password: ""
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false
      });
    }, 2000);

    console.log(response);
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert theme="success" className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else if (this.state.error) {
      return (
        <Alert theme="danger" className="mb-0">
          <i className="fa fa-danger mx-2"></i> Error !! {this.state.errorCode}
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  InputFields() {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="fieldName">User Name</label>
                    <FormInput
                      id="fieldName"
                      type="text"
                      placeholder="User Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Email </label>

                    <FormInput
                      id="fieldName"
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Hospital </label>

                    <FormInput
                      id="fieldName"
                      type="text"
                      placeholder="Hospital name"
                      name="hospital"
                      onChange={this.handleChange}
                      value={this.state.hospital}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Password </label>

                    <FormInput
                      id="fieldName"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={this.handleChange}
                      value={this.state.password}
                      required
                    />
                  </Col>
                </Row>
                <Button onClick={this.sendData}>Submit</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }

  AddNewField = () => {
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Create Form"
              subtitle="Add Form Fields"
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col md="6">
              <this.InputFields />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return this.AddNewField();
  }
}
