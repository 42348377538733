import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  FormSelect,
  FormInput,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormTextarea,
  Alert,
  FormCheckbox,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";

const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.getFormFields = this.getFormFields.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.RenderFields = this.RenderFields.bind(this);
    this.firstSend = this.firstSend.bind(this);
    this.nextData = this.nextData.bind(this);
    this.prevData = this.prevData.bind(this);
    this.ipCheck = this.ipCheck.bind(this);
    this.BottomButton = this.BottomButton.bind(this);
    this.TopTab = this.TopTab.bind(this);
    this.CustomRadio = this.CustomRadio.bind(this);
    this.CustomCheckBox = this.CustomCheckBox.bind(this);
    this.redirectState = this.redirectState.bind(this);
    this.alert = this.alert.bind(this);
    this.fileUpload = this.fileUpload.bind(this);
    this.uploadLoading = this.uploadLoading.bind(this);
    this.state = {
      formFields: [],
      fieldValue: {},
      checkBoxValue: [],
      sliceArray: [],
      start: 0,
      end: 1,
      id: null,
      selectedFile: false,
      isLoading: false
    };
  }

  async getFormFields() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response) {
      this.setState({
        formFields: response.data.data,
        fieldValue: {
          centre_525: response.data.hospitalName,
          serial_number_833:
            response.data.hospitalId + "000" + response.data.count
        }
      });
      //console.log(response.data.data);

      let fields = [];
      let sliceNo = [];

      fields = response.data.data;

      fields.forEach((item, key) => {
        // console.log(key, item.fieldType);
        if (item.fieldType === "title") {
          sliceNo.push(key);
        }
      });
      sliceNo.push(fields.length);

      this.setState({
        sliceArray: sliceNo
      });

      //------
    }
  }

  handleChange(e) {
    if (e.target.name === "hospital_ip_number_643") {
      this.ipCheck(e.target.value);
    }

    var merged;
    let newItem;
    let oldItem = this.state.fieldValue;

    if (
      e.target.name === "systolic_blood_pressure_684" ||
      e.target.name === "diastolic_blood_pressure_702"
    ) {
      newItem = {
        [e.target.name]: e.target.value
      };
      merged = { ...oldItem, ...newItem };
      this.setState(
        {
          fieldValue: merged
        },
        () => {
          let sbp = this.state.fieldValue.systolic_blood_pressure_684 || 0;
          let dbp = this.state.fieldValue.diastolic_blood_pressure_702 || 0;
          let pp = sbp - dbp;
          let map = Number(dbp) + Number(pp * 0.333);

          newItem = {
            pulse_pressure_mmhg_923: pp,
            mean_arterial_pressure_mmhg_110: map
          };
          let oldItem = this.state.fieldValue;
          merged = { ...oldItem, ...newItem };
          this.setState({
            fieldValue: merged
          });
        }
      );
    } else if (e.target.name === "sgpt_445" || e.target.name === "sgot_634") {
      newItem = {
        [e.target.name]: e.target.value
      };
      merged = { ...oldItem, ...newItem };
      this.setState(
        {
          fieldValue: merged
        },
        () => {
          let sgpt = this.state.fieldValue.sgpt_445 || 0;
          let sgot = this.state.fieldValue.sgot_634 || 0;
          let ratio = (sgot / sgpt).toFixed(2);

          newItem = {
            sgotsgpt_205: ratio
          };
          let oldItem = this.state.fieldValue;
          merged = { ...oldItem, ...newItem };
          this.setState({
            fieldValue: merged
          });
        }
      );
    } else if (
      e.target.name === "no_of_lcma_stend_size_102" ||
      e.target.name === "no_lad_stend_size_102" ||
      e.target.name === "no_rca_stend_size_102" ||
      e.target.name === "no_ramus_stend_size_102" ||
      e.target.name === "no_lcxom_stend_size_102" ||
      e.target.name === "no_diagonal_stend_size_102"
    ) {
      newItem = {
        [e.target.name]: e.target.value
      };
      merged = { ...oldItem, ...newItem };
      this.setState(
        {
          fieldValue: merged
        },
        () => {
          let lcma =
            Number(this.state.fieldValue.no_of_lcma_stend_size_102) || 0;
          let lad = Number(this.state.fieldValue.no_lad_stend_size_102) || 0;
          let rca = Number(this.state.fieldValue.no_rca_stend_size_102) || 0;
          let ramus =
            Number(this.state.fieldValue.no_ramus_stend_size_102) || 0;
          let lcxom =
            Number(this.state.fieldValue.no_lcxom_stend_size_102) || 0;
          let diagnol =
            Number(this.state.fieldValue.no_diagonal_stend_size_102) || 0;
          let totalStent = lcma + lad + rca + ramus + lcxom + diagnol;

          newItem = {
            total_no_stend_342: totalStent
          };
          let oldItem = this.state.fieldValue;
          merged = { ...oldItem, ...newItem };
          this.setState({
            fieldValue: merged
          });
        }
      );
    } else {
      newItem = { [e.target.name]: e.target.value };
      merged = { ...oldItem, ...newItem };
      this.setState({
        fieldValue: merged
      });
    }

    // console.log(this.state.fieldValue);
  }

  async ipCheck(ipNo) {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/ipcheck/${ipNo}`,

      headers: { Authorization: `Bearer ${token}` }
    });
    if (response2.data.error) {
      this.setState({
        admissionCount: 0,
        ipMessage: response2.data.msg,
        ipErr: true
      });
    } else if (response2.data.statusCode === 200) {
      this.setState({
        admissionCount: response2.data.data,
        ipMessage: " ",
        ipErr: false
      });
    } else {
      this.setState({
        admissionCount: 0,
        ipMessage: " ",
        ipErr: false
      });
    }
  }

  async fileUpload(e) {
    let name = e.target.name;
    this.setState({
      selectedFile: true
    });
    const data = new FormData();
    data.append("image", e.target.files[0]);

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let response = await axios
      .post(`${URL.BASE_URL}/forms/files`, data, config)
      .catch(function(error) {
        console.log(error);
      });

    if (!response.data.error && response.data.statusCode === 200) {
      this.setState({
        [name]: response.data.filename,
        selectedFile: false
      });
    }
    //console.log(name);
  }

  handleCheck(e) {
    let item = e.target.name;
    let value = e.target.value;
    let newItem = [];

    if (e.target.checked) {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      newItem.push(value);
    } else {
      if (this.state[item]) {
        newItem = this.state[item];
      }
      //newItem.pop(value);
      var index = newItem.indexOf(value);
      if (index !== -1) {
        newItem.splice(index, 1);
      }
    }

    this.setState({
      [item]: newItem
    });
  }

  alert() {
    if (this.state.validation) {
      //window.scrollTo(0, 0);
      let msg = "";
      if (!this.state.fieldValue.name_149) {
        msg = "Name Can Not Be Empty";
      } else if (!this.state.fieldValue.centre_525) {
        msg = "Center Can Not Be Empty";
      } else if (!this.state.fieldValue.serial_number_833) {
        msg = "Serial No Can Not Be Empty";
      } else if (!this.state.fieldValue.hospital_ip_number_643) {
        msg = "Hospital IP No Can Not Be Empty";
      } else if (!this.state.fieldValue.age_675) {
        msg = "Age Can Not Be Empty";
      } else if (
        !this.state.fieldValue.date_of_discharge_317_dd ||
        !this.state.fieldValue.date_of_discharge_317_mm ||
        !this.state.fieldValue.date_of_discharge_317_yyyy
      ) {
        msg = "Date of Discharge Can Not Be Empty";
      } else if (
        !this.state.fieldValue.date_of_admission_153_dd ||
        !this.state.fieldValue.date_of_admission_153_mm ||
        !this.state.fieldValue.date_of_admission_153_yyyy
      ) {
        msg = "Date of Admission Can Not Be Empty";
      }

      if (msg === "") {
        return <div></div>;
      } else
        return (
          <>
            <Alert theme="danger" className="mb-0">
              <i className="fa fa-info mx-2"></i> Error {msg}
            </Alert>
          </>
        );
    } else {
      return <div></div>;
    }
  }

  nextData() {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
    let dod_dd = this.state.fieldValue.date_of_discharge_317
      ? this.state.fieldValue.date_of_discharge_317
      : this.state.fieldValue.date_of_discharge_317_dd;
    let dod_mm = this.state.fieldValue.date_of_discharge_317
      ? this.state.fieldValue.date_of_discharge_317
      : this.state.fieldValue.date_of_discharge_317_mm;
    let dod_yyyy = this.state.fieldValue.date_of_discharge_317
      ? this.state.fieldValue.date_of_discharge_317
      : this.state.fieldValue.date_of_discharge_317_yyyy;
    let doa_dd = this.state.fieldValue.date_of_admission_153
      ? this.state.fieldValue.date_of_admission_153
      : this.state.fieldValue.date_of_admission_153_dd;
    let doa_mm = this.state.fieldValue.date_of_admission_153
      ? this.state.fieldValue.date_of_admission_153
      : this.state.fieldValue.date_of_admission_153_mm;
    let doa_yyyy = this.state.fieldValue.date_of_admission_153
      ? this.state.fieldValue.date_of_admission_153
      : this.state.fieldValue.date_of_admission_153_yyyy;
    if (
      // false
      !this.state.fieldValue.name_149 ||
      !this.state.fieldValue.centre_525 ||
      !this.state.fieldValue.serial_number_833 ||
      !this.state.fieldValue.hospital_ip_number_643 ||
      !dod_dd ||
      !dod_mm ||
      !dod_yyyy ||
      !doa_dd ||
      !doa_mm ||
      !doa_yyyy
    ) {
      this.setState({
        validation: true
      });
    } else {
      this.setState({
        validation: false
      });
      let oldStart = this.state.start;
      let oldEnd = this.state.end;
      this.setState({
        start: oldStart + 1,
        end: oldEnd + 1
      });
      window.scrollTo(0, 0);
      console.log(this.state);
      this.firstSend();
    }
  }

  prevData() {
    let oldStart = this.state.start;
    let oldEnd = this.state.end;
    this.setState({
      start: oldStart - 1,
      end: oldEnd - 1
    });
    window.scrollTo(0, 0);
  }

  async firstSend(e) {
    //e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let stateData = Object.assign({}, this.state);
    stateData.date_of_admission_153 = `${stateData.fieldValue.date_of_admission_153_mm}-${stateData.fieldValue.date_of_admission_153_dd}-${stateData.fieldValue.date_of_admission_153_yyyy}`;
    stateData.date_of_discharge_317 = `${stateData.fieldValue.date_of_discharge_317_mm}-${stateData.fieldValue.date_of_discharge_317_dd}-${stateData.fieldValue.date_of_discharge_317_yyyy}`;
    //delete stateData.fieldValue.date_of_admission_153_dd;
    //delete stateData.fieldValue.date_of_admission_153_mm;
    //delete stateData.fieldValue.date_of_admission_153_yyyy;
    //delete stateData.fieldValue.date_of_discharge_317_dd;
    //delete stateData.fieldValue.date_of_discharge_317_mm;
    //delete stateData.fieldValue.date_of_discharge_317_yyyy;
    delete stateData.formFields;
    delete stateData.fieldValue;
    delete stateData.checkBoxValue;
    delete stateData.start;
    delete stateData.end;
    delete stateData.sliceArray;
    delete stateData.id;
    delete stateData.isCompleted;
    delete stateData.selectedFile;
    delete stateData.isLoading;

    let totalDrugs = this.getTotalNoOfDrugs();

    let otherDrugs = this.state.fieldValue.no_of_other_drugs_464
      ? Number(this.state.fieldValue.no_of_other_drugs_464)
      : 0;

    stateData.total_no_of_drugs_358 = totalDrugs;
    stateData.total_drugs_529 = totalDrugs + otherDrugs;

    let dataToSend = { ...this.state.fieldValue, ...stateData };
    this.setState({
      fieldValue: dataToSend
    });

    // console.log(dataToSend);
    // console.log("Sening");
    let response;
    if (this.state.start + 1 === this.state.sliceArray.length - 1) {
      dataToSend.isCompleted = true;
    }
    if (this.state.id == null && this.state.start === 0) {
      response = await axios
        .post(`${URL.BASE_URL}/forms/adddata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
      if (response.data.data) {
        this.setState({
          id: response.data.data._id
        });
      }
    } else if (this.state.id != null) {
      dataToSend.id = this.state.id;
      response = await axios
        .patch(`${URL.BASE_URL}/forms/updatedata`, dataToSend, config)
        .catch(function(error) {
          console.log(error);
        });
      //console.log(response);
    }
  }

  dateChecker = () => {
    let stateData = this.state;
    let date_of_admission = `${stateData.fieldValue.date_of_admission_153_mm}-${stateData.fieldValue.date_of_admission_153_dd}-${stateData.fieldValue.date_of_admission_153_yyyy}`;
    let date_of_discharge = `${stateData.fieldValue.date_of_discharge_317_mm}-${stateData.fieldValue.date_of_discharge_317_dd}-${stateData.fieldValue.date_of_discharge_317_yyyy}`;
    date_of_admission = new Date(date_of_admission).getTime();
    date_of_discharge = new Date(date_of_discharge).getTime();
    if (date_of_discharge < date_of_admission) {
      return (
        <div style={{ color: "red" }}>
          Date of Discharge / Death is behind Date of Admission
        </div>
      );
    } else {
      return <></>;
    }
  };

  redirectState() {
    this.firstSend();
    this.setState({
      isCompleted: true
    });
  }

  pagination(pageNo) {
    this.setState({
      isLoading: true
    });

    setTimeout(
      function() {
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
    if (
      // false
      !this.state.fieldValue.name_149 ||
      !this.state.fieldValue.centre_525 ||
      !this.state.fieldValue.serial_number_833 ||
      !this.state.fieldValue.hospital_ip_number_643 ||
      !this.state.fieldValue.age_675 ||
      !this.state.fieldValue.date_of_discharge_317 ||
      !this.state.fieldValue.date_of_admission_153
    ) {
      this.setState({
        validation: true
      });
    } else {
      this.setState({
        validation: false
      });

      this.setState({
        start: pageNo - 1,
        end: pageNo
      });
      window.scrollTo(0, 0);
      //  console.log(this.state);
      this.firstSend();
    }
  }

  TopTab() {
    return (
      <div>
        <nav>
          <ul className="pagination">
            {this.state.sliceArray.map((item, index) => {
              let titleIndex = this.state.sliceArray;
              if (index === this.state.start + 1) {
                return (
                  <li className="page-item ">
                    <Button
                      size="sm"
                      theme="info"
                      className="mb-2 mr-1"
                      onClick={() => this.pagination(index)}
                    >
                      {index} &nbsp;
                      {this.state.formFields[titleIndex[index - 1]].fieldName}
                    </Button>
                  </li>
                );
              } else if (index === 0) {
                return <div></div>;
              } else {
                return (
                  <li className="page-item">
                    <Button
                      size="sm"
                      outline
                      theme="info"
                      className="mb-2 mr-1"
                      onClick={() => this.pagination(index)}
                    >
                      {index} &nbsp;
                      {this.state.formFields[titleIndex[index - 1]].fieldName}
                    </Button>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </div>
    );
  }

  StendTable = () => {
    return (
      <div
        style={{
          margin: "20px"
        }}
      >
        <p>
          Note: If multiple stents are used in a single vessel, please mention
          the number of stents first. Then type the dimensions of each stent
          separated by a comma (eg: number of stents : 2 and dimensions: 3,2.5
          in diameter and 22,26 in length).
        </p>
        <table
          style={{
            boxShadow: "5px 5px 19px 0px rgba(50, 50, 50, 0.5)",
            borderRadius: "10px"
          }}
          className="table mb-0"
        >
          <thead>
            <tr>
              <th scope="col" className="border-0">
                Parameter
              </th>
              <th scope="col" className="border-0">
                Yes / No
              </th>
              <th scope="col" className="border-0">
                No. of stent used
              </th>
              <th scope="col" className="border-0">
                Stent size
              </th>
              <th scope="col" className="border-0">
                Stent length
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LMCA</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="lcma_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.lcma_yes_no_101
                      ? this.state.fieldValue.lcma_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_of_lcma_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_of_lcma_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lcma_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lcma_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lcma_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lcma_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lcma_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lcma_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.lcma_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
            <tr>
              <td>LAD</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="lad_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.lad_yes_no_101
                      ? this.state.fieldValue.lad_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_lad_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_lad_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lad_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lad_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lad_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lad_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lad_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lad_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.lad_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
            <tr>
              <td>RCA</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="rca_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.rca_yes_no_101
                      ? this.state.fieldValue.rca_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_rca_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_rca_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.rca_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"rca_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["rca_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.rca_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"rca_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["rca_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.rca_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
            <tr>
              <td>LCX/OM</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="lcxom_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.lcxom_yes_no_101
                      ? this.state.fieldValue.lcxom_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_lcxom_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_lcxom_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lcxom_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lcxom_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lcxom_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.lcxom_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"lcxom_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["lcxom_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.lcxom_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Ramus</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="ramus_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.ramus_yes_no_101
                      ? this.state.fieldValue.ramus_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_ramus_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_ramus_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.ramus_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"ramus_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["ramus_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.ramus_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"ramus_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["ramus_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.ramus_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
            <tr>
              <td>Diagonal</td>
              <td>
                <FormSelect
                  id="feInputState1"
                  name="diagonal_yes_no_101"
                  onChange={this.handleChange}
                >
                  <option>
                    {this.state.fieldValue.diagonal_yes_no_101
                      ? this.state.fieldValue.diagonal_yes_no_101
                      : "Select One"}
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </FormSelect>
              </td>
              <td>
                <input
                  type="number"
                  name={"no_diagonal_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["no_diagonal_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.diagonal_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"diagonal_stend_size_102"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["diagonal_stend_size_102"]}
                  disabled={
                    this.state.fieldValue.diagonal_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  name={"diagonal_stend_length_103"}
                  onChange={this.handleChange}
                  value={this.state.fieldValue["diagonal_stend_length_103"]}
                  disabled={
                    this.state.fieldValue.diagonal_yes_no_101 === "Yes"
                      ? false
                      : true
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
        <Col md="5" className="mt-3">
          <FormGroup>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText
                  style={{
                    color: "#99a",
                    "font-size": "14px",
                    "font-weight": "500"
                  }}
                >
                  Total no of stent used{" "}
                </InputGroupText>
              </InputGroupAddon>
              <FormInput
                type="number"
                min="80"
                name="total_no_stend_342"
                onChange={this.handleChange}
                value={this.state.fieldValue.total_no_stend_342}
                disabled
              ></FormInput>
            </InputGroup>
          </FormGroup>
        </Col>
      </div>
    );
  };
  BottomButton() {
    let start = this.state.start;
    let end = this.state.end;
    // console.log(this.state.sliceArray, start, end);
    let arrayLength = this.state.sliceArray.length;
    if (start === 0) {
      return (
        <div>
          <strong style={{ color: "red", fontSize: "18px" }}>
            {this.state.ipMessage}
          </strong>
          <nav>
            <ul className="pagination">
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                        disabled={this.state.ipErr}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                  disabled={this.state.ipErr}
                >
                  Next
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start === arrayLength - 2 && end === arrayLength - 1) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.redirectState}
                >
                  Submit
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else if (start > 0 && end < arrayLength) {
      return (
        <div>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <Button
                  size="sm"
                  theme="warning"
                  className="mb-2 mr-1"
                  onClick={this.prevData}
                >
                  Prev
                </Button>
              </li>
              {this.state.sliceArray.map((item, index) => {
                if (index === this.state.start + 1) {
                  return (
                    <li className="page-item ">
                      <Button
                        size="sm"
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                } else if (index === 0) {
                  return <div></div>;
                } else {
                  return (
                    <li className="page-item">
                      <Button
                        size="sm"
                        outline
                        theme="info"
                        className="mb-2 mr-1"
                        onClick={() => this.pagination(index)}
                      >
                        {index}
                      </Button>
                    </li>
                  );
                }
              })}

              <li className="page-item">
                <Button
                  size="sm"
                  theme="primary"
                  className="mb-2 mr-1"
                  onClick={this.nextData}
                >
                  Next
                </Button>
              </li>
              <li className="page-item">
                <Button
                  size="sm"
                  theme="success"
                  className="mb-2 mr-1"
                  onClick={this.redirectState}
                >
                  Save
                </Button>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div>" "</div>;
    }
  }

  CustomRadio({ name, item }) {
    if (item === this.state.fieldValue[name]) {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked
          ></input>
          {item}
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            id="radio1"
            name={name || "name"}
            value={item || "item"}
            onChange={this.handleChange}
            style={{
              marginTop: "2px",
              marginRight: "10px",

              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
          ></input>
          {item}
        </>
      );
    }
  }

  CustomCheckBox({ name, item }) {
    let arr = [];
    if (this.state[name]) {
      arr = this.state[name];
    } else if (this.state.fieldValue[name]) {
      arr = this.state.fieldValue[name];
    }
    // console.log(name, item);
    let isChecked = arr.includes(item);
    return (
      <>
        <Col>
          <input
            type="checkbox"
            id="checkbox1"
            style={{
              fontSize: "16px",
              border: "0px",
              width: "20px",
              height: "20px"
            }}
            defaultChecked={isChecked}
            name={name}
            value={item}
            onChange={this.handleCheck}
          />
          &nbsp; &nbsp; &nbsp;
          {item}
        </Col>
      </>
    );
  }

  uploadLoading({ slug }) {
    var fileName;
    fileName = this.state[slug];
    if (this.state.selectedFile) {
      return (
        <>
          <Row
            style={{
              marginTop: "25px"
            }}
          >
            <Col md="1">
              <ReactLoading
                type={"spin"}
                color={"#5af"}
                height={"25px"}
                width={"25px"}
              />
            </Col>
            <Col md="3">
              <strong>Uploading...</strong>
            </Col>
          </Row>
        </>
      );
    } else {
      if (fileName) {
        return (
          <div>
            <a href={`${URL.BASE_URL}/${fileName}`} target="_blank">
              View File
            </a>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }

  Validation = ({ min, max, value, name, msg }) => {
    if (value < min || value > max) {
      if (msg) {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              {msg}
            </Alert>
          </>
        );
      } else {
        return (
          <>
            <Alert
              theme="warning"
              style={{
                fontSize: "18px"
              }}
            >
              {name} - should be in between {min} to {max}
            </Alert>
          </>
        );
      }
    } else {
      return <div></div>;
    }
  };

  customDate = ({ date, name }) => {
    let mm = date ? date.split("-")[0] : null;
    let dd = date ? date.split("-")[1] : null;
    let yyyy = date ? date.split("-")[2] : null;
    const dayArr = Array.from(Array(31).keys());
    const monthArr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const yearArr = [2018, 2019, 2020, 2021, 2022, 2023];
    const optionStyle = {
      padding: "5px",
      borderRadius: "3px",
      borderColor: "#ccc",
      color: "#aaa"
    };
    return (
      <>
        <select
          onChange={this.handleChange}
          style={optionStyle}
          id="date"
          name={`${name}_dd`}
        >
          <option value={dd ? dd : ""}>{dd ? dd : "Date"}</option>
          {dayArr.map(item => {
            return (
              <option value={("0" + (item + 1)).slice(-2)}>
                {("0" + (item + 1)).slice(-2)}
              </option>
            );
          })}
        </select>
        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="month"
          name={`${name}_mm`}
        >
          <option value={mm ? mm : ""}>
            {mm ? monthArr[mm - 1] : "Month"}
          </option>

          {monthArr.map((item, key) => {
            return <option value={("0" + (key + 1)).slice(-2)}>{item}</option>;
          })}
        </select>

        <select
          style={optionStyle}
          onChange={this.handleChange}
          id="year"
          name={`${name}_yyyy`}
        >
          <option value={yyyy ? yyyy : ""}>{yyyy ? yyyy : "Year"}</option>

          {yearArr.map((item, key) => {
            return <option value={item}>{item}</option>;
          })}
        </select>
        <this.dateChecker />
      </>
    );
  };

  getTotalNoOfDrugs = () => {
    var totalDrugs = 0;

    totalDrugs += this.state.aspirin_226 // For ANTIPLATELET
      ? this.state.aspirin_226.length
      : this.state.fieldValue.aspirin_226
      ? this.state.fieldValue.aspirin_226.length
      : 0;

    totalDrugs += this.state.if_yes_276 // For Statin
      ? this.state.if_yes_276.length
      : this.state.fieldValue.if_yes_276
      ? this.state.fieldValue.if_yes_276.length
      : 0;

    totalDrugs += this.state.if_yes_486 // For Non Statin
      ? this.state.if_yes_486.length
      : this.state.fieldValue.if_yes_486
      ? this.state.fieldValue.if_yes_486.length
      : 0;

    totalDrugs += this.state.metoprolol_516 // For BETA-BLOCKER
      ? this.state.metoprolol_516.length
      : this.state.fieldValue.metoprolol_516
      ? this.state.fieldValue.metoprolol_516.length
      : 0;

    totalDrugs += this.state.ramipril_120 // For RAS-BLOCKER ACEI
      ? this.state.ramipril_120.length
      : this.state.fieldValue.ramipril_120
      ? this.state.fieldValue.ramipril_120.length
      : 0;

    totalDrugs += this.state.losartan_284 // For RAS-BLOCKER ARB
      ? this.state.losartan_284.length
      : this.state.fieldValue.losartan_284
      ? this.state.fieldValue.losartan_284.length
      : 0;

    if (this.state.fieldValue.arni_641 === "Yes") {
      //For RAS-BLOCKER ARNI
      totalDrugs++;
    }

    totalDrugs += this.state.aldactonespironolactone_584 // For MRA
      ? this.state.aldactonespironolactone_584.length
      : this.state.fieldValue.aldactonespironolactone_584
      ? this.state.fieldValue.aldactonespironolactone_584.length
      : 0;

    totalDrugs += this.state.isolazine_218 // For Vasodilator
      ? this.state.isolazine_218.length
      : this.state.fieldValue.isolazine_218
      ? this.state.fieldValue.isolazine_218.length
      : 0;

    totalDrugs += this.state.if_yes_126 // For SECOND LINE DRUGS FOR HYPERTENSION
      ? this.state.if_yes_126.length
      : this.state.fieldValue.if_yes_126
      ? this.state.fieldValue.if_yes_126.length
      : 0;

    totalDrugs += this.state.nitrate_266 // For ANTIANGINAL
      ? this.state.nitrate_266.length
      : this.state.fieldValue.nitrate_266
      ? this.state.fieldValue.nitrate_266.length
      : 0;

    totalDrugs += this.state.verapamil_351 // For CALCIUM CHANNEL BLOCKER
      ? this.state.verapamil_351.length
      : this.state.fieldValue.verapamil_351
      ? this.state.fieldValue.verapamil_351.length
      : 0;

    if (this.state.fieldValue.digoxin_146 === "Yes") {
      //for digoxin
      //For RAS-BLOCKER ARNI
      totalDrugs++;
    }

    if (this.state.fieldValue.ivabradine_772 === "Yes") {
      // For Ivabradine
      //For RAS-BLOCKER ARNI
      totalDrugs++;
    }

    if (this.state.fieldValue.amiodarone_294 === "Yes") {
      // For amiodarone

      totalDrugs++;
    }

    totalDrugs += this.state.warfarin_warf_652 // For OAC -> VKA
      ? this.state.warfarin_warf_652.length
      : this.state.fieldValue.warfarin_warf_652
      ? this.state.fieldValue.warfarin_warf_652.length
      : 0;

    totalDrugs += this.state.dabigatran_844 // For OAC -> NOAC
      ? this.state.dabigatran_844.length
      : this.state.fieldValue.dabigatran_844
      ? this.state.fieldValue.dabigatran_844.length
      : 0;

    totalDrugs += this.state.lasix_219 // For DIURETIC
      ? this.state.lasix_219.length
      : this.state.fieldValue.lasix_219
      ? this.state.fieldValue.lasix_219.length
      : 0;

    if (this.state.fieldValue.insulin_428 === "Yes") {
      // For TREATMENT OF DIABETES
      totalDrugs++;
    }

    totalDrugs += this.state.glimepiride_898 // For OAD-SULFONYLUREA
      ? this.state.glimepiride_898.length
      : this.state.fieldValue.glimepiride_898
      ? this.state.fieldValue.glimepiride_898.length
      : 0;

    if (this.state.fieldValue.oadmetformin_950 === "Yes") {
      // For TREATMENT OF DIABETES
      totalDrugs++;
    }

    totalDrugs += this.state.teneligliptin_533 // For OAD-SULFONYLUREA
      ? this.state.teneligliptin_533.length
      : this.state.fieldValue.teneligliptin_533
      ? this.state.fieldValue.teneligliptin_533.length
      : 0;

    totalDrugs += this.state.empagliflozin_566 // For OAD-SULFONYLUREA
      ? this.state.empagliflozin_566.length
      : this.state.fieldValue.empagliflozin_566
      ? this.state.fieldValue.empagliflozin_566.length
      : 0;

    if (this.state.fieldValue.oadvoglibose_944 === "Yes") {
      // For TREATMENT OF DIABETES
      totalDrugs++;
    }

    totalDrugs += this.state.if_yes_805 // For GASTRIC PROTECTION DRUG
      ? this.state.if_yes_805.length
      : this.state.fieldValue.if_yes_805
      ? this.state.fieldValue.if_yes_805.length
      : 0;

    totalDrugs += this.state.if_yes_511 // For GASTRIC PROTECTION DRUG
      ? this.state.if_yes_511.length
      : this.state.fieldValue.if_yes_511
      ? this.state.fieldValue.if_yes_511.length
      : 0;

    return totalDrugs;
  };

  RenderFields() {
    //For Second page other if condition
    let arr = [];
    if (this.state["chief_presenting_complaint_248"]) {
      arr = this.state["chief_presenting_complaint_248"];
    } else if (this.state.fieldValue["chief_presenting_complaint_248"]) {
      arr = this.state.fieldValue["chief_presenting_complaint_248"];
    }

    let arr2 = []; // For 9th page OAC Other
    if (this.state["dabigatran_844"]) {
      arr2 = this.state["dabigatran_844"];
    } else if (this.state.fieldValue["dabigatran_844"]) {
      arr2 = this.state.fieldValue["dabigatran_844"];
    }

    let arr3 = []; // For 10th page Table
    if (this.state["type_of_pci_543"]) {
      arr3 = this.state["type_of_pci_543"];
    } else if (this.state.fieldValue["type_of_pci_543"]) {
      arr3 = this.state.fieldValue["type_of_pci_543"];
    }

    let arr4 = []; // For 7th page Other Infective Endocarditis
    if (this.state["if_yes_952"]) {
      arr4 = this.state["if_yes_952"];
    } else if (this.state.fieldValue["if_yes_952"]) {
      arr4 = this.state.fieldValue["if_yes_952"];
    }

    var found;
    var found2;
    var found3;
    var found4;

    if (arr) {
      found = arr.includes("Other");
    }

    if (arr2) {
      found2 = arr2.includes("Others");
    }

    if (arr3) {
      found3 = arr3.includes("Stent") || arr3.includes("Both");
    }

    if (arr4) {
      found4 =
        arr4.includes("Other") &&
        this.state.fieldValue.infective_endocarditis_576 === "Yes";
    }
    //endd --------------------------------------
    let fields = [];

    if (this.state.formFields) {
      fields = this.state.formFields;
    }

    let start = 0;
    let end = this.state.sliceArray[this.state.end];
    if (this.state.start > 0) {
      start = this.state.sliceArray[this.state.start];
    }

    return (
      <div>
        {fields.slice(start, end).map(field => {
          var parent_name;
          var parent_name2;
          var parent_name3;
          //  console.log("----------->");
          //  console.log(field.parentSlug);
          if (field.parentSlug) {
            parent_name = this.state.fieldValue[field.parentSlug];
          }
          if (field.parentSlug2) {
            parent_name2 = this.state.fieldValue[field.parentSlug2];
          }
          if (field.parentSlug3) {
            parent_name3 = this.state.fieldValue[field.parentSlug3];
          }

          // console.log("pos ------>", field.parent);
          //   console.log(" value ------>", parent_name);
          //  console.log(found);
          //  console.log("------------------------");

          //For multi parent issue

          //FOR 2nd page Others
          if (field.parentSlug === "chief_presenting_complaint_248" && found) {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //FOR 2nd page Others END

          //FOR 9th page OAC Others
          if (field.fieldSlug === "apixaban_956" && found2) {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //FOR 9th page OAC Others

          //------------

          //FOR 9th page Infective Endocarditis Others
          if (field.fieldSlug === "mention_745" && found4) {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //FOR 9th page Infective Endocarditis Others

          //for If CAD 7th page

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            field.fieldSlug === "if_acs_220" &&
            this.state.fieldValue.if_cad_263 === "ACS"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            this.state.fieldValue.if_cad_263 === "ACS" &&
            field.parentSlug === "if_acs_220" &&
            this.state.fieldValue.if_acs_220 === "STEMI"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            this.state.fieldValue.if_cad_263 === "ACS" &&
            this.state.fieldValue.if_acs_220 === "STEMI" &&
            field.fieldSlug === "if_iwmi_922" &&
            this.state.fieldValue.if_stemi_518 === "IWMI"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            this.state.fieldValue.if_cad_263 === "ACS" &&
            this.state.fieldValue.if_acs_220 === "STEMI" &&
            this.state.fieldValue.if_stemi_thrombolysed_816 ===
              "Thrombolysed" &&
            (field.fieldSlug === "if_thrombolysed_agent_449" ||
              field.fieldSlug === "dose_of_thrombolytic_agent_526" ||
              field.fieldSlug === "specify_dose_714")
          ) {
            if (field.fieldSlug === "specify_dose_714") {
              return (
                <>
                  <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else {
              return (
                <>
                  <Row>
                    <Col md="3">{field.fieldName} : </Col>
                    {field.radioEnum.map(item => {
                      return (
                        <>
                          <this.CustomRadio
                            name={field.fieldSlug}
                            item={item}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      );
                    })}
                  </Row>
                  <hr />
                </>
              );
            }
          }

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            this.state.fieldValue.if_cad_263 === "ACS" &&
            this.state.fieldValue.if_acs_220 === "STEMI" &&
            this.state.fieldValue.if_stemi_thrombolysed_816 === "Primary PCI" &&
            (field.fieldSlug === "if_primary_pci_culprit_vessel_970" ||
              field.fieldSlug === "route_168")
          ) {
            return (
              <>
                <Row>
                  <Col md={{ size: 3 }}>{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <Row>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Row>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.cad_843 === "Yes" &&
            this.state.fieldValue.if_cad_263 === "ACS" &&
            this.state.fieldValue.if_acs_220 === "STEMI" &&
            this.state.fieldValue.if_stemi_mechanical_complication_726 ===
              "Yes" &&
            field.fieldSlug === "if_yes_935"
          ) {
            return (
              <>
                <Row>
                  <Col md={{ size: 3 }}>{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          }

          //END

          //For 7th page Pulmonary embolism

          if (
            this.state.fieldValue.pulmonary_embolism_909 === "Yes" &&
            field.fieldSlug === "agent_997" &&
            this.state.fieldValue.treatment_549 === "Thrombolysis"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          //END

          //For Comorrbities  For & page Lsat

          if (
            this.state.fieldValue.comorbities_present_919 === "Yes" &&
            this.state.fieldValue.if_yes_684 === "General" &&
            (field.fieldSlug === "anemia_11_852" ||
              field.fieldSlug === "renal_dysfunction_472" ||
              field.fieldSlug === "liver_disease_776" ||
              field.fieldSlug === "electrolyte_abnormality_532" ||
              field.fieldSlug === "infection_686" ||
              field.fieldSlug === "neurological_disease_787" ||
              field.fieldSlug === "psychatric_illness_114" ||
              field.fieldSlug === "thyroid_disease_278" ||
              field.fieldSlug === "cancer_342")
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.comorbities_present_919 === "Yes" &&
            this.state.fieldValue.if_yes_684 === "CV Risk" &&
            field.fieldSlug === "cv_risk_117"
          ) {
            return (
              <>
                <Row>
                  <Col md={{ size: 3 }}>{field.fieldName} : </Col>
                  {field.checkBoxEnum.map((item, key) => {
                    return (
                      <>
                        <this.CustomCheckBox
                          name={field.fieldSlug}
                          item={item}
                        />
                      </>
                    );
                  })}
                </Row>

                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.comorbities_present_919 === "Yes" &&
            this.state.fieldValue.if_yes_684 === "Both" &&
            (field.fieldSlug === "anemia_11_852" ||
              field.fieldSlug === "renal_dysfunction_472" ||
              field.fieldSlug === "liver_disease_776" ||
              field.fieldSlug === "electrolyte_abnormality_532" ||
              field.fieldSlug === "infection_686" ||
              field.fieldSlug === "neurological_disease_787" ||
              field.fieldSlug === "psychatric_illness_114" ||
              field.fieldSlug === "thyroid_disease_278" ||
              field.fieldSlug === "cancer_342" ||
              field.fieldSlug === "cv_risk_117")
          ) {
            if (field.fieldSlug === "cv_risk_117") {
              return (
                <>
                  <Row>
                    <Col md={{ size: 3 }}>{field.fieldName} : </Col>
                    {field.checkBoxEnum.map((item, key) => {
                      return (
                        <>
                          <this.CustomCheckBox
                            name={field.fieldSlug}
                            item={item}
                          />
                        </>
                      );
                    })}
                  </Row>

                  <hr />
                </>
              );
            } else {
              return (
                <>
                  <Row>
                    <Col md="3">{field.fieldName} : </Col>
                    {field.radioEnum.map(item => {
                      return (
                        <>
                          <this.CustomRadio
                            name={field.fieldSlug}
                            item={item}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      );
                    })}
                  </Row>
                  <hr />
                </>
              );
            }
          }

          //For No of Drugs in 8th Page

          if (field.fieldSlug === "no_of_antiplatelet_530") {
            // let no_of_antiplatlet = 0;
            let no_of_antiplatlet = this.state.aspirin_226
              ? this.state.aspirin_226.length
              : 0;
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={no_of_antiplatlet}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }

          //For TREATMENT FOR CHOLESTEROL Page

          if (
            field.fieldSlug === "if_yes_276" &&
            this.state.fieldValue.statin_778 === "Yes"
          ) {
            return (
              <>
                <Col md={{ size: 10, offset: field.parent ? 1 : 0 }}>
                  <Row>
                    <Col md={{ size: 4 }}>{field.fieldName} : </Col>
                  </Row>
                  <Row className="mt-2">
                    {field.checkBoxEnum.map((item, key) => {
                      return (
                        <>
                          <Col md="4">
                            <this.CustomCheckBox
                              name={field.fieldSlug}
                              item={item}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </Col>
                <hr />
              </>
            );
          }

          if (
            field.fieldSlug === "atorvastatin_value_800" ||
            field.fieldSlug === "rosuvastatin_value_926"
          ) {
            if (this.state.if_yes_276) {
              if (
                field.fieldSlug === "rosuvastatin_value_926" &&
                this.state.if_yes_276.includes("Rosuvastatin")
              ) {
                return (
                  <>
                    <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              }
              if (
                field.fieldSlug === "atorvastatin_value_800" &&
                this.state.if_yes_276.includes("Atorvastatin")
              ) {
                return (
                  <>
                    <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else {
                return <div></div>;
              }
            } else {
              return <></>;
            }
          }

          //NOAC dose
          let noacArr = this.state.dabigatran_844
            ? this.state.dabigatran_844
            : this.state.fieldValue.dabigatran_844;
          if (
            field.fieldSlug === "dabigatran_dose_mg_822" ||
            field.fieldSlug === "apixaban_dose_mg_397" ||
            field.fieldSlug === "rivaroxaban_dose_mg_771"
          ) {
            if (noacArr) {
              if (
                field.fieldSlug === "dabigatran_dose_mg_822" &&
                noacArr.includes("Dabigatran")
              ) {
                return (
                  <>
                    <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              }
              if (
                field.fieldSlug === "apixaban_dose_mg_397" &&
                noacArr.includes("Apixaban")
              ) {
                return (
                  <>
                    <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              }
              if (
                field.fieldSlug === "rivaroxaban_dose_mg_771" &&
                noacArr.includes("Rivaroxaban")
              ) {
                return (
                  <>
                    <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <hr />
                  </>
                );
              } else {
                return <div></div>;
              }
            } else {
              return <></>;
            }
          }
          var totalDrugs = 0;
          if (
            field.fieldSlug === "total_no_of_drugs_358" ||
            field.fieldSlug === "total_drugs_529"
          ) {
            //NOTE For total no of drugs count
            let totalDrugs = this.getTotalNoOfDrugs();

            if (field.fieldSlug === "total_drugs_529") {
              totalDrugs += this.state.fieldValue.no_of_other_drugs_464
                ? Number(this.state.fieldValue.no_of_other_drugs_464)
                : 0;
            }

            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={totalDrugs}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }

          //For Last page dead condition
          if (
            this.state.fieldValue.in_hospital_death_mortality_321 === "Yes" &&
            field.fieldSlug === "if_noncardiac_death_reason_214" &&
            this.state.fieldValue.cause_of_death_342 === "Non-Cardiac"
          ) {
            return (
              <>
                <Row>
                  <Col md="3">{field.fieldName} : </Col>
                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.in_hospital_death_mortality_321 === "Yes" &&
            field.fieldSlug === "specify_563" &&
            this.state.fieldValue.if_noncardiac_death_reason_214 === "Others"
          ) {
            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={this.state.fieldValue[field.fieldSlug]}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //END

          //For Duration of hospital stay

          if (field.fieldSlug === "duration_of_hospital_stay_534") {
            let duration =
              new Date(this.state.fieldValue.date_of_discharge_317) -
              new Date(this.state.fieldValue.date_of_admission_153);
            const diffDays = Math.ceil(duration / (1000 * 60 * 60 * 24));

            return (
              <>
                <Col md="5" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name={field.fieldSlug}
                        onChange={this.handleChange}
                        value={diffDays}
                        disabled
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </>
            );
          }
          //END

          //FOR 7th page CAD+HF Yes to No Conversion

          //For last long radio
          if (
            this.state.fieldValue.hf_184 === "Yes" &&
            field.fieldSlug === "if_yes_659" &&
            this.state.fieldValue.hf_due_to_acs_266 === "No"
          ) {
            return (
              <>
                <Row>
                  <Col md={{ size: 1, offset: 1 }}>{field.fieldName} : </Col>
                  <Col lg="10">
                    {field.radioEnum.map(item => {
                      return (
                        <>
                          <this.CustomRadio
                            name={field.fieldSlug}
                            item={item}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      );
                    })}
                  </Col>
                </Row>
                <hr />
              </>
            );
          }

          if (
            this.state.fieldValue.hf_due_to_acs_266 !== "No" &&
            field.fieldSlug === "if_yes_659"
          ) {
            return <></>;
          }

          //END

          if (field.parent && parent_name !== "Yes") {
            return <div> </div>;
          } else if (field.parentSlug2 && parent_name2 !== "Yes") {
            return <div> </div>;
          } else if (field.parentSlug3 && parent_name3 !== "Yes") {
            return <div> </div>;
          } else if (field.fieldSlug === "sgotsgpt_205") {
            return (
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      {field.fieldName}{" "}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="number"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                    value={this.state.fieldValue[field.fieldSlug]}
                    disabled
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            );
          } else if (field.fieldType === "text") {
            if (
              field.fieldSlug === "centre_525" ||
              field.fieldSlug === "serial_number_833"
            ) {
              return (
                <>
                  <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={
                            field.fieldSlug === "centre_525"
                              ? this.state.fieldValue.centre_525
                              : this.state.fieldValue.serial_number_833
                          }
                          disabled
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            } else {
              return (
                <>
                  <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                    <this.Validation
                      min={field.minValue}
                      max={field.maxValue}
                      value={this.state.fieldValue[field.fieldSlug]}
                      name={field.fieldName}
                    />{" "}
                  </Col>
                  <hr />
                </>
              );
            }
          } else if (field.fieldType === "number") {
            if (field.fieldSlug === "age_675") {
              return (
                <>
                  <Row>
                    <Col md="5" className="ml-3">
                      <FormGroup>
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText
                              style={{
                                color: "#99a",
                                "font-size": "14px",
                                "font-weight": "500"
                              }}
                            >
                              {field.fieldName}{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <FormInput
                            type="number"
                            min="80"
                            name={field.fieldSlug}
                            onChange={this.handleChange}
                            value={this.state.fieldValue[field.fieldSlug]}
                          ></FormInput>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col>
                      <p> * Only accepts age from 80 & Above</p>
                    </Col>
                  </Row>
                  <this.Validation
                    min={field.minValue}
                    max={field.maxValue}
                    value={this.state.fieldValue[field.fieldSlug]}
                    name={field.fieldName}
                    msg={"Recheck the Age"}
                  />{" "}
                  <hr />
                </>
              );
            } else {
              return (
                <>
                  <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="number"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          value={this.state.fieldValue[field.fieldSlug]}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                    <this.Validation
                      min={field.minValue}
                      max={field.maxValue}
                      value={this.state.fieldValue[field.fieldSlug]}
                      name={field.fieldName}
                    />{" "}
                  </Col>
                  <hr />
                </>
              );
            }
          } else if (field.fieldType === "radio") {
            return (
              <>
                <Row>
                  <Col md={{ size: 3, offset: field.parent ? 1 : 0 }}>
                    {field.fieldName} :{" "}
                  </Col>

                  {field.radioEnum.map(item => {
                    return (
                      <>
                        <this.CustomRadio name={field.fieldSlug} item={item} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    );
                  })}
                </Row>
                <hr />
              </>
            );
          } else if (field.fieldType === "check_box") {
            return (
              <>
                <Col md={{ size: 10, offset: field.parent ? 1 : 0 }}>
                  <Row>
                    <Col md={{ size: 4 }}>{field.fieldName} : </Col>
                  </Row>
                  <Row className="mt-2">
                    {field.checkBoxEnum.map((item, key) => {
                      return (
                        <>
                          <Col md="4">
                            <this.CustomCheckBox
                              name={field.fieldSlug}
                              item={item}
                            />
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "dropdown") {
            return (
              <>
                <Col md={{ size: 3, offset: field.parent ? 1 : 0 }}>
                  <label htmlFor="feInputState"> {field.fieldName} </label>:{" "}
                  <FormSelect
                    id="feInputState"
                    name={field.fieldSlug}
                    onChange={this.handleChange}
                  >
                    <option>{this.state.fieldValue[field.fieldSlug]}</option>
                    {field.checkBoxEnum.map(item => {
                      return (
                        <>
                          <option>{item}</option>
                        </>
                      );
                    })}
                  </FormSelect>
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "date") {
            if (field.fieldSlug === "date_of_discharge_317") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <this.customDate
                      name={field.fieldSlug}
                      date={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else if (field.fieldSlug === "date_of_death_968") {
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <this.customDate
                      name={field.fieldSlug}
                      date={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
            } else
              return (
                <>
                  <Col>
                    <label htmlFor="date"> {field.fieldName} </label>
                    &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
                    <this.customDate
                      name={field.fieldSlug}
                      date={this.state.fieldValue[field.fieldSlug]}
                    />
                  </Col>
                  <hr />
                </>
              );
          } else if (field.fieldType === "title") {
            return (
              <>
                <Col md="10">
                  <h2> {field.fieldName} </h2>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "sub_title") {
            return (
              <>
                <Col md={{ size: 10, offset: field.parent ? 1 : 0 }}>
                  <h3>
                    {" "}
                    <strong> {field.fieldName} </strong>{" "}
                  </h3>
                  <hr />
                </Col>
              </>
            );
          } else if (field.fieldType === "text_area") {
            return (
              <>
                <Col md={{ size: 5, offset: field.parent ? 1 : 0 }}>
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          {field.fieldName}{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormTextarea
                    name={field.fieldSlug}
                    value={this.state.fieldValue[field.fieldSlug]}
                    onChange={this.handleChange}
                  />
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "table" && found3) {
            return (
              <>
                <Col md="10">
                  <strong> Stented vessel(s) </strong>

                  <this.StendTable />
                  <hr />
                </Col>
              </>
            );
          } else if (
            field.fieldType === "image" ||
            field.fieldType === "file"
          ) {
            return (
              <>
                {field.fieldName} &nbsp;&nbsp; : &nbsp;&nbsp;
                <Col md="5" sm="12">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="customFile2"
                    name={field.fieldSlug}
                    onChange={this.fileUpload}
                  />
                  <label className="custom-file-label" htmlFor="customFile2">
                    {this.state[field.fieldSlug]}
                  </label>
                  <this.uploadLoading slug={field.fieldSlug} />
                </Col>
                <hr />
              </>
            );
          } else if (field.fieldType === "document") {
            if (field.fieldSlug === "readmission_475") {
              return (
                <>
                  <Col md="5" sm="12">
                    <FormGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon type="prepend">
                          <InputGroupText
                            style={{
                              color: "#99a",
                              "font-size": "14px",
                              "font-weight": "500"
                            }}
                          >
                            {field.fieldName}{" "}
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          type="text"
                          name={field.fieldSlug}
                          onChange={this.handleChange}
                          disabled
                          value={this.state.admissionCount}
                        ></FormInput>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <hr />
                </>
              );
            }
          } else if (field.fieldType === "note") {
            return (
              <>
                <Col md="12">
                  <Alert theme="warning" className="mb-5 ">
                    <i className="fa fa-info mx-2"></i>
                    <strong> {field.fieldName} </strong>{" "}
                  </Alert>
                </Col>
              </>
            );
          }
          //return <li key={field.fieldSlug}>{field.fieldType}</li>;
        })}
        <this.BottomButton />
        <br />
        <strong>
          Steps : {this.state.start + 1} / {this.state.sliceArray.length - 1}
        </strong>
      </div>
    );
  }

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <this.alert />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <this.TopTab />
          <Col md="12">
            <this.RenderFields />
          </Col>
        </Row>
        <this.alert />
      </Container>
    );
  };

  componentDidMount() {
    this.getFormFields();
  }

  render() {
    if (this.state.isCompleted) {
      // return <Redirect to="../dashbord" />;
      return (
        <div>
          <Modal open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <Link to="../dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    }
    if (this.state.sliceArray.length < 1) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          style={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            marginLeft: "40%"
          }}
        >
          <Row className="justify-content-md-center">
            <Col lg="2">
              <ReactLoading type={"spinningBubbles"} color={"#5af"} />
              {/* <h2>Loading...</h2> */}
            </Col>
          </Row>
        </div>
      );
    } else {
      return this.AddNewPost();
    }
  }
}
