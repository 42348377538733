import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  FormInput,
  Alert,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);
    this.getSingleForm = this.getSingleForm.bind(this);
    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);
    this.conditions = this.conditions.bind(this);
    this.conditions2 = this.conditions2.bind(this);
    this.conditions3 = this.conditions3.bind(this);
    this.conditions4 = this.conditions4.bind(this);

    this.state = {
      status: false,
      dateWarnig: ""
    };
  }

  async getSingleForm() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    let id = this.props.match.params.id;
    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/singledata/${id}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      ipNo: response2.data.data.fields.hospital_ip_number_643,
      name: response2.data.data.fields.name_149,
      formId: id,
      dateOfDisCharge: response2.data.data.fields.date_of_discharge_317
    });

    // console.log(this.state.fieldValue);
    // console.log(response2.data.data);
  }

  handleChange(e) {
    if (
      e.target.name === "date_of_death" ||
      e.target.name === "date_of_follow"
    ) {
      let dateOfFollowUp =
        e.target.name === "date_of_death"
          ? new Date(this.state.date_of_follow)
          : new Date(e.target.value);
      let dateOfDeath =
        e.target.name === "date_of_death"
          ? new Date(e.target.value)
          : new Date(this.state.date_of_death);
      //console.log("🔥🚩", dateOfFollowUp > dateOfDeath);
      if (dateOfFollowUp > dateOfDeath) {
        this.setState({
          // date_of_follow: e.target.value
          dateWarnig:
            "The Date of follow up is beyond Date of Death. Change the Follow Up Date to the Date entered for Death. "
        });
      } else {
        this.setState({
          // date_of_follow: e.target.value
          dateWarnig: " "
        });
      }
      this.setState({
        [e.target.name]: e.target.value
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);

    delete stateData.formId;
    delete stateData.name;
    delete stateData.ipNo;
    delete stateData.status;

    let followUpData = {
      followdata: stateData,
      name: this.state.name,
      ipNo: this.state.ipNo,
      formId: this.state.formId
    };

    console.log(followUpData);
    console.log("Sening");
    let response = await axios
      .post(`${URL.BASE_URL}/forms/addfollowup`, followUpData, config)
      .catch(function(error) {
        console.log(error);
        return;
      });

    if (response) {
      if (response.data.statusCode === 200) {
        this.setState({
          status: true,
          isCompleted: true
        });
      } else {
        this.setState({
          status: false
        });
      }
    }

    setTimeout(() => {
      this.setState({
        status: false
      });
    }, 2000);

    console.log(response);
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  conditions() {
    if (this.state.recurrent === "Yes" && this.state.alivedead === "alive") {
      return (
        <Row>
          <FormGroup>
            <Col md="5" sm="12">
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      No. of times hospitalized
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="text"
                    onChange={this.handleChange}
                    name="notimeshosp"
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
            <hr />
          </FormGroup>
          <hr />
        </Row>
      );
    } else {
      return <div></div>;
    }
  }
  conditions2() {
    if (this.state.alivedead === "dead") {
      return (
        <>
          <Row>
            <Col>
              <label htmlFor="date"> Date of death : </label>
              &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
              <input
                type="date"
                id="date"
                name="date_of_death"
                onKeyDown={e => e.preventDefault()}
                onChange={this.handleChange}
                style={{
                  padding: "5px 5px",
                  margin: "8px 0",
                  "box-sizing": "border-box",
                  color: "#ccc",
                  border: "1px solid #ccc",
                  "border-radius": "4px"
                }}
              />
              <p style={{ fontSize: "14px" }}>
                {" "}
                *Click the <i class="far fa-calendar"></i> icon for choosing
                date
              </p>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col md="3">Place of Death :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio10"
                name="place_of_death"
                value="Hospital"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Hospital
              <input
                type="radio"
                id="radio11"
                name="place_of_death"
                value="Home"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Home
            </FormGroup>
          </Row>
          <hr />

          <Row>
            <Col md="3">Cause of Death :</Col>
            <input
              type="radio"
              id="radio1"
              name="cause_death"
              value="Cardiac"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Cardiac
            <input
              type="radio"
              id="radio2"
              name="cause_death"
              value="Non Cardiac"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Non cardiac
          </Row>
          <hr />
        </>
      );
    } else {
      return <div></div>;
    }
  }
  conditions3() {
    if (
      this.state.cause_death === "Non Cardiac" &&
      this.state.alivedead === "dead"
    ) {
      return (
        <>
          <Row>
            <Col md="3">If Non Cardiac Death, Reason :</Col>
            <input
              type="radio"
              id="radio22"
              name="if_non_cardiac"
              value="Unknown"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Unknown
            <input
              type="radio"
              id="radio23"
              name="if_non_cardiac"
              value="Infection"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Infection
            <input
              type="radio"
              id="radio23"
              name="if_non_cardiac"
              value="RTA"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            RTA
            <input
              type="radio"
              id="radio23"
              name="if_non_cardiac"
              value="Cancer"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Cancer
            <input
              type="radio"
              id="radio23"
              name="if_non_cardiac"
              value="Covid"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Covid
            <input
              type="radio"
              id="radio23"
              name="if_non_cardiac"
              value="Others"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Others
          </Row>
          <hr />
        </>
      );
    } else {
      return <div></div>;
    }
  }
  conditions4() {
    if (
      this.state.if_non_cardiac === "Others" &&
      this.state.alivedead === "dead"
    ) {
      return (
        <Row>
          <FormGroup>
            <Col md="12" sm="12">
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon type="prepend">
                    <InputGroupText
                      style={{
                        color: "#99a",
                        "font-size": "14px",
                        "font-weight": "500"
                      }}
                    >
                      Specify
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    type="text"
                    name="specify"
                    onChange={this.handleChange}
                  ></FormInput>
                </InputGroup>
              </FormGroup>
            </Col>
            <hr />
          </FormGroup>
        </Row>
      );
    } else {
      return <div></div>;
    }
  }
  conditions5 = () => {
    if (this.state.alivedead === "alive") {
      return (
        <div>
          <Row>
            <Col md="3">Symptom status :</Col>
            <input
              type="radio"
              id="radio2"
              name="symptomSts"
              value="Same"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Same
            <input
              type="radio"
              id="radio4"
              name="symptomSts"
              value="Better"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Better
            <input
              type="radio"
              id="radio5"
              name="symptomSts"
              value="Worse"
              onChange={this.handleChange}
              style={{
                marginTop: "2px",
                marginLeft: "20px",
                marginRight: "10px",
                fontSize: "16px",
                border: "0px",
                width: "20px",
                height: "20px"
              }}
            ></input>
            Worse
          </Row>
          <hr />{" "}
          <Row>
            <Col md="3">Medication adherence :</Col>
            <FormGroup>
              <input
                type="radio"
                id="radio6"
                name="medication"
                value="Yes"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio7"
                name="medication"
                value="No"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />
          <Row>
            <Col md="3">Repeat hospitalisation </Col>
            <FormGroup>
              <input
                type="radio"
                id="radio8"
                name="recurrent"
                value="Yes"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              Yes
              <input
                type="radio"
                id="radio9"
                name="recurrent"
                value="No"
                onChange={this.handleChange}
                style={{
                  marginTop: "2px",
                  marginLeft: "20px",
                  marginRight: "10px",
                  fontSize: "16px",
                  border: "0px",
                  width: "20px",
                  height: "20px"
                }}
              ></input>
              No
            </FormGroup>
          </Row>
          <hr />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  monthDiff = () => {
    let d1;
    let d2;

    d2 = this.state.date_of_follow
      ? new Date(this.state.date_of_follow)
      : new Date();
    d1 = this.state.dateOfDisCharge
      ? new Date(this.state.dateOfDisCharge)
      : new Date();
    var diff = (d2.getTime() - d1.getTime()) / 1000;
    var diff2 = (d2.getTime() - d1.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 30;
    diff2 /= 60 * 60 * 24;
    var day = Math.abs(Math.round(diff2) - 30 * Math.floor(diff));
    var month = Math.abs(Math.floor(diff));
    //console.log("Month 🚀", diff);
    // console.log("Day ✨", day);

    return { month: month, day: day };
  };

  dateChecker = () => {
    let date_of_followUp = this.state.date_of_follow;
    let date_of_discharge = this.state.dateOfDisCharge;
    date_of_followUp = new Date(date_of_followUp).getTime();
    date_of_discharge = new Date(date_of_discharge).getTime();
    if (date_of_discharge > date_of_followUp) {
      return (
        <div style={{ color: "red" }}>
          Follow Up Date Should be after the Date of Discharge
        </div>
      );
    } else {
      return <></>;
    }
  };

  InputFields() {
    let duration = this.monthDiff();

    if (this.state.is_followup_done === "Yes") {
      return (
        <div>
          <Row>
            <Col>
              <label htmlFor="date"> Date of Follow Up </label>
              &nbsp;:&nbsp; &nbsp; &nbsp; &nbsp;
              <input
                type="date"
                id="date"
                name="date_of_follow"
                onKeyDown={e => e.preventDefault()}
                value={this.state.date_of_follow}
                onChange={this.handleChange}
                style={{
                  padding: "5px 5px",
                  margin: "8px 0",
                  "box-sizing": "border-box",
                  color: "#ccc",

                  border: "1px solid #ccc",
                  "border-radius": "4px"
                }}
              />
              <p style={{ fontSize: "14px" }}>
                {" "}
                *Click the <i class="far fa-calendar"></i> icon for choosing
                date
              </p>
              <this.dateChecker />
            </Col>
          </Row>
          <p style={{ color: "red" }}>{this.state.dateWarnig}</p>

          <hr />
          <Row>
            <FormGroup>
              <Col md="12" sm="12">
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon type="prepend">
                      <InputGroupText
                        style={{
                          color: "#99a",
                          "font-size": "14px",
                          "font-weight": "500"
                        }}
                      >
                        Duration
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput
                      type="text"
                      name="duration"
                      value={
                        duration.month + " Months and " + duration.day + " Days"
                      }
                      onChange={this.handleChange}
                      disabled
                    ></FormInput>
                  </InputGroup>
                </FormGroup>
              </Col>

              <hr />
            </FormGroup>
          </Row>

          <div>
            <FormGroup>
              <Row>
                <Col md="3">Alive/Dead :</Col>
                <input
                  type="radio"
                  id="radio1"
                  name="alivedead"
                  value="alive"
                  onChange={this.handleChange}
                  style={{
                    marginTop: "2px",
                    marginRight: "10px",
                    fontSize: "16px",
                    border: "0px",
                    width: "20px",
                    height: "20px"
                  }}
                ></input>
                Alive
                <input
                  type="radio"
                  id="radio2"
                  name="alivedead"
                  value="dead"
                  onChange={this.handleChange}
                  style={{
                    marginTop: "2px",
                    marginLeft: "20px",
                    marginRight: "10px",
                    fontSize: "16px",
                    border: "0px",
                    width: "20px",
                    height: "20px"
                  }}
                ></input>
                Dead
              </Row>
              <hr />
            </FormGroup>
            <this.conditions5></this.conditions5>

            <this.conditions></this.conditions>

            <this.conditions2></this.conditions2>

            <this.conditions3></this.conditions3>

            <this.conditions4></this.conditions4>

            <Row>
              <FormGroup>
                <Col md="12" sm="12">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon type="prepend">
                        <InputGroupText
                          style={{
                            color: "#99a",
                            "font-size": "14px",
                            "font-weight": "500"
                          }}
                        >
                          Additional information, if any
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormInput
                        type="text"
                        name="addinfo"
                        onChange={this.handleChange}
                      ></FormInput>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <hr />
              </FormGroup>
            </Row>
          </div>
          <Button
            size="sm"
            theme="success"
            className="mb-2 mr-1"
            onClick={this.sendData}
            disabled={this.state.dateWarnig != " "}
          >
            Submit
          </Button>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  AddNewField = () => {
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */} <h2>Followup details</h2>
          <hr />
          <Row>
            <Col md="12">
              <Row>
                <Col md="6">
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "1.2rem"
                    }}
                  >
                    Name : {this.state.name} <br />
                    IP No : {this.state.ipNo}
                    <br />
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md="4">Is Follow Up Done ? :</Col>
                <FormGroup>
                  <input
                    type="radio"
                    id="radio20"
                    name="is_followup_done"
                    value="Yes"
                    onChange={this.handleChange}
                    style={{
                      marginTop: "2px",
                      marginRight: "10px",
                      fontSize: "16px",
                      border: "0px",
                      width: "20px",
                      height: "20px"
                    }}
                  ></input>
                  Yes
                  <input
                    type="radio"
                    id="radio21"
                    name="is_followup_done"
                    value="No"
                    onChange={this.handleChange}
                    style={{
                      marginTop: "2px",
                      marginLeft: "20px",
                      marginRight: "10px",
                      fontSize: "16px",
                      border: "0px",
                      width: "20px",
                      height: "20px"
                    }}
                  ></input>
                  No
                </FormGroup>
              </Row>
              <hr />
              <this.InputFields />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  componentDidMount() {
    this.getSingleForm();
  }

  render() {
    if (this.state.isCompleted) {
      return (
        <div>
          <Modal open={true}>
            <ModalHeader>Data Entered Successfully</ModalHeader>
            <ModalBody>
              <Link to="../dashbord">Goto Dashboard</Link>
            </ModalBody>
          </Modal>
        </div>
      );
    } else return this.AddNewField();
  }
}
