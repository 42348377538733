import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Alert } from "shards-react";
import DashbordCard from "../components/clean/dashbordCard";
import DashbordTable from "../components/clean/dashbordTable";
import PageTitle from "./../components/common/PageTitle";
import { Link } from "react-router-dom";

const BlogOverview = ({ smallStats }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle
        title=" Overview"
        subtitle="Dashboard"
        className="text-sm-left mb-3"
      />
    </Row>

    {/* Small Stats Blocks */}
    <DashbordCard />
    <Row className="mt-5">
      <Col lg="12">
        <Alert theme="primary" className="mb-0">
          <i className="fa fa-info mx-2"></i>
          <Link
            to="ABBREVIATIONS.pdf"
            style={{ color: "white" }}
            target="_blank"
          >
            List of Abbreviations
          </Link>
        </Alert>
      </Col>
    </Row>
    {/* <DashbordTable /> */}
  </Container>
);

export default BlogOverview;
