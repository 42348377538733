import React, { Component } from "react";
import { Row, Col, Slider } from "shards-react";
import ReactLoading from "react-loading";

import "./css/dashbordCard.css";

import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class dashbordTable extends Component {
  constructor(props) {
    super(props);
    this.getBasicSts = this.getBasicSts.bind(this);
    this.DataTable = this.DataTable.bind(this);
    this.handleSlide = this.handleSlide.bind(this);

    this.state = {
      isLoaded: false,
      table_data: []
    };
  }

  async getBasicSts() {
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response2 = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/table-data`,

      headers: { Authorization: `Bearer ${token}` }
    });
    if (response2.data.statusCode === 200) {
      this.setState({
        table_data: response2.data.data,
        isLoaded: true
      });
    }

    console.log(response2.data.data);
    //console.log(response.data.data);
  }

  componentDidMount() {
    this.getBasicSts();
  }

  handleSlide(e) {
    this.setState({
      value: [parseInt(e[0]), parseInt(e[1])]
    });
  }

  DataTable() {
    let data = this.state.table_data;

    return (
      <>
        <Row className="mt-5 d-flex justify-content-center ">
          <Col lg="10" md="12">
            <table className="table table-bordered">
              <thead className="bg-primary">
                <tr>
                  <th scope="col">Feature</th>
                  <th scope="col">%</th>
                  <th scope="col">Number</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  console.log(item.isTitle);
                  if (item.isTitle) {
                    return (
                      <>
                        <tr class="table-primary">
                          <th scope="row">{item.feature}</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <tr>
                          <th scope="row">{item.feature}</th>
                          <td>{item.percentage}</td>
                          <td>{item.number}</td>
                        </tr>
                      </>
                    );
                  }
                })}
              </tbody>
            </table>
          </Col>
        </Row>
      </>
    );
  }

  Loading() {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          marginLeft: "40%"
        }}
      >
        <Row className="justify-content-md-center">
          <Col lg="2">
            <ReactLoading type={"spinningBubbles"} color={"#5af"} />
            {/* <h2>Loading...</h2> */}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    if (this.state.isLoaded) {
      return (
        <>
          <this.DataTable />
        </>
      );
    } else {
      return <this.Loading />;
    }
  }
}
