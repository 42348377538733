import React, { Component } from "react";
import { NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  CardHeader,
  Card,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
import ReactLoading from "react-loading";

const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class ListFormData extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.getData = this.getData.bind(this);
    this.state = {
      limit: 20,
      page: 1,
      patientData: [],
      open: false,
      nameAndId: {
        fields: {}
      },
      loading: true
    };
  }

  async getData(p) {
    // console.log(l, p);

    this.setState({ loading: true });

    let page = p ? p : this.state.page;

    let limit = this.state.limit;

    let searchText = this.state.searchText
      ? `searchText=${this.state.searchText}`
      : null;

    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;

    let response = await axios({
      method: "get",
      url: `${URL.BASE_URL}/forms/getdatalist/${page}/${limit}?${searchText}`,

      headers: { Authorization: `Bearer ${token}` }
    });

    this.setState({
      countDetails: response.data.countDetails,
      patientData: response.data.data,
      token: token,
      page: page,
      loading: false
    });

    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let response2 = await axios
      .get(`${URL.BASE_URL}/fields/get`, config)
      .catch(error => {
        console.log(error);
        this.setState({
          error: error
        });
      });
    if (response2) {
      this.setState({
        formFields: response2.data.data
      });
    }
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  toggle2() {
    this.setState({
      open2: !this.state.open2
    });
  }

  ModalPopUp(data) {
    this.setState({
      open: !this.state.open,
      nameAndId: data
    });
    console.log(data);
  }

  deletePatient = async id => {
    let token = this.state.token;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/forms/delete/${id}`, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.getData();
      this.toggle();
    }
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: Number(e.target.value)
    });
    if (e.target.name === "limit") {
      this.getData(e.target.value);
    } else if (e.target.name === "nextBtn") {
      this.setState({
        page: this.state.page + 1
      });
      this.getData();
    } else if (e.target.name === "prevBtn" && this.state.page > 0) {
      this.setState({
        page: this.state.page - 1
      });
      this.getData();
    }
  };

  SearchBar = () => {
    return (
      <>
        <Row>
          <Col md="6" className="form-group">
            <FormInput
              id="parentValue"
              type="text"
              placeholder="Search By Patient Name, Serial number or IP Number"
              name="searchText"
              onChange={this.handleChange}
            />
          </Col>
          <Col lg="1">
            <Button
              onClick={() => this.getData(1)}
              name="prevBtn"
              theme="warning"
              className="mb-2 mr-1"
            >
              Search
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  RenderTable = () => {
    if (this.state.loading) {
      return (
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <this.SearchBar />
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Center
                  </th>
                  <th scope="col" className="border-0">
                    Serial number
                  </th>
                  <th scope="col" className="border-0">
                    Hospital ID
                  </th>
                  <th scope="col" className="border-0">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <Row className="justify-content-md-center mt-5">
              <Col lg="2">
                <ReactLoading type={"spinningBubbles"} color={"#5af"} />
                {/* <h2>Loading...</h2> */}
              </Col>
            </Row>
          </CardBody>

          <CardFooter>
            <this.Pagination />
          </CardFooter>
        </Card>
      );
    } else if (this.state.patientData.length === 0) {
      return (
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <this.SearchBar />
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Center
                  </th>
                  <th scope="col" className="border-0">
                    Serial number
                  </th>
                  <th scope="col" className="border-0">
                    Hospital ID
                  </th>
                  <th scope="col" className="border-0">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
            <Row className="justify-content-md-center mt-5">
              <Col lg="2">
                <p>No Data found</p>
              </Col>
            </Row>
          </CardBody>

          <CardFooter></CardFooter>
        </Card>
      );
    } else {
      return (
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <this.SearchBar />
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                    #
                  </th>
                  <th scope="col" className="border-0">
                    Name
                  </th>
                  <th scope="col" className="border-0">
                    Center
                  </th>
                  <th scope="col" className="border-0">
                    Serial number
                  </th>
                  <th scope="col" className="border-0">
                    Hospital ID
                  </th>
                  <th scope="col" className="border-0">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.patientData.map((data, no) => {
                  return (
                    <tr>
                      <td>{no + 1}</td>
                      <td>{data.fields.name_149}</td>
                      <td>{data.fields.centre_525}</td>
                      <td>{data.fields.serial_number_833}</td>
                      <td>{data.fields.hospital_ip_number_643}</td>
                      <td>
                        <Link to={`patientdata/${data._id}`}>
                          <Button theme="success" className="mb-2 mr-1">
                            Edit <i class="fas fa-edit"></i>
                          </Button>
                        </Link>

                        <Button
                          theme="danger"
                          className="mb-2 mr-1"
                          onClick={() => this.ModalPopUp(data)}
                        >
                          Delete <i class="fas fa-trash-alt"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div>
              <Modal open={this.state.open} toggle={this.toggle}>
                <ModalHeader>
                  Are you sure you want to delete this item?
                </ModalHeader>
                <ModalBody>
                  {this.state.nameAndId.fields.name} will be deleted <br />
                  <Button
                    theme="danger"
                    className="mb-2 mr-1"
                    onClick={() => this.deletePatient(this.state.nameAndId._id)}
                  >
                    Yes, Delete
                  </Button>
                  <Button
                    theme="success"
                    className="mb-2 mr-1"
                    onClick={this.toggle}
                  >
                    Cancel
                  </Button>
                </ModalBody>
              </Modal>
            </div>
          </CardBody>

          <CardFooter>
            <this.Pagination />
          </CardFooter>
        </Card>
      );
    }
  };

  Pagination = () => {
    let totalPages = 0;
    let totalCount = 0;
    let perPage = this.state.limit;
    let currentPage = this.state.page;
    let hasNextPage = false;
    if (this.state.countDetails) {
      totalCount = this.state.countDetails.totalCount;
      totalPages = this.state.countDetails.totalPages;
      perPage = this.state.countDetails.perPage;
      hasNextPage = this.state.countDetails.hasNextPage;
    }
    let pageArr = [...Array(totalPages).keys()];

    return (
      <Row>
        Pages : &nbsp;&nbsp;&nbsp;
        {pageArr.map((item, key) => {
          if (currentPage === key + 1) {
            return (
              <div>
                <Button onClick={() => this.getData(key + 1)} pill>
                  {key + 1}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            );
          } else {
            return (
              <div>
                <Button onClick={() => this.getData(key + 1)} outline pill>
                  {key + 1}
                </Button>
                &nbsp;&nbsp;&nbsp;
              </div>
            );
          }
        })}
      </Row>
    );
  };

  AddNewPost = () => {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="List Form"
            subtitle="List Form Fields"
            className="text-sm-left"
          />
        </Row>
        <Row>
          <Col md="12">
            <this.RenderTable />
          </Col>
        </Row>
      </Container>
    );
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return this.AddNewPost();
  }
}
