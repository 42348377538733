export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashbord",
      htmlBefore: '<i class="material-icons">edit</i>',
      htmlAfter: ""
    },

    {
      title: "Add Patient data",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-formdata"
    },
    {
      title: "List incomplete Patient ",
      htmlBefore: '<i class="material-icons">warning</i>',
      to: "/list-formdata"
    },
    {
      title: "List Patient ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/incomplete"
    },
    {
      title: "Finished  Datas ",
      htmlBefore: '<i class="material-icons">healing</i>',
      to: "/finished"
    },
    {
      title: "Logout ",
      htmlBefore: '<i class="material-icons">&#xE879;</i>',
      to: "/logout"
    }
  ];
}
