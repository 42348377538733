import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  Alert,
  FormSelect,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");

export default class AddFormData extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.InputFields = this.InputFields.bind(this);
    this.addToList = this.addToList.bind(this);
    this.AddToArray = this.AddToArray.bind(this);
    this.sendData = this.sendData.bind(this);
    this.alert = this.alert.bind(this);

    this.state = {
      field_type: "text",
      arryItem: [],
      arayType: "",
      status: false
    };
  }

  handleChange(e) {
    console.log(this.state);
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  addToList(e) {
    e.preventDefault();
    let oldList = this.state.arryItem;
    let item = this.state[e.target.name];
    oldList.push(item);
    this.setState({
      arryItem: oldList
    });
    console.log(this.state);
    if (this.state.field_type === "radio") {
      this.setState({
        arayType: "radio"
      });
    } else if (this.state.field_type === "dropdown") {
      this.setState({
        arayType: "checkBox"
      });
    } else {
      this.setState({
        arayType: "checkBox"
      });
    }
  }

  async sendData(e) {
    e.preventDefault();
    const token = Cookie.get("jwtToken") ? Cookie.get("jwtToken") : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    let stateData = Object.assign({}, this.state);

    if (stateData.arayType === "checkBox") {
      stateData.check_box_items = stateData.arryItem;
    } else if (stateData.arayType === "radio") {
      stateData.radio_items = stateData.arryItem;
    } else if (stateData.arayType === "dropdown") {
      stateData.drop_items = stateData.arryItem;
    }
    delete stateData.arryItem;
    delete stateData.arayType;
    delete stateData.status;
    stateData.is_unique = "false";
    console.log(stateData);

    console.log(stateData);
    console.log("Sening");
    let response = await axios
      .post(`${URL.BASE_URL}/fields/add`, stateData, config)
      .catch(function(error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        status: true
      });
    } else {
      this.setState({
        status: false
      });
    }
    setTimeout(() => {
      this.setState({
        status: false
      });
    }, 2000);

    console.log(response);
  }

  AddToArray() {
    if (
      this.state.field_type === "check_box" ||
      this.state.field_type === "radio" ||
      this.state.field_type === "dropdown"
    ) {
      let arayName = "check_box_items";
      if (this.state.field_type === "radio") {
        arayName = "radio_items";
      }
      if (this.state.field_type === "dropdown") {
        arayName = "drop_items";
      }
      return (
        <Col md="12">
          <Row>
            <Col md="8" className="form-group">
              <label htmlFor="fieldName">Items</label>
              <FormInput
                id="fieldName"
                type="text"
                placeholder="Check Box / Radio items"
                name={arayName}
                onChange={this.handleChange}
              />
            </Col>
            <Col md="1">
              <label htmlFor="fieldName"> &nbsp; &nbsp; </label>
              <Button theme="success" onClick={this.addToList} name={arayName}>
                +
              </Button>
            </Col>
          </Row>
          <Row>
            <ul>
              {this.state.arryItem.map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Row>
        </Col>
      );
    } else {
      return <div></div>;
    }
  }

  alert() {
    if (this.state.status) {
      return (
        <Alert className="mb-0">
          <i className="fa fa-info mx-2"></i> Successfully added fields
        </Alert>
      );
    } else {
      return <div></div>;
    }
  }

  InputFields() {
    return (
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="fieldName">Field Name</label>
                    <FormInput
                      id="fieldName"
                      type="text"
                      placeholder="Field Name"
                      name="field_name"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="position">Position</label>
                    <FormInput
                      id="position"
                      type="number"
                      placeholder="Position"
                      name="pos"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="parent">Parent position</label>
                    <FormInput
                      id="parent"
                      type="number"
                      placeholder="Parent position"
                      name="parent"
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="feInputState">Field type</label>

                    <FormSelect
                      id="feInputState"
                      name="field_type"
                      onChange={this.handleChange}
                    >
                      <option>text</option>
                      <option>number</option>
                      <option>boolean</option>
                      <option>dropdown</option>
                      <option>check_box</option>
                      <option>radio</option>
                      <option>date</option>
                      <option>image</option>
                      <option>document</option>
                      <option>title</option>
                      <option>sub_title</option>
                      <option>other</option>
                    </FormSelect>
                  </Col>

                  <this.AddToArray />
                </Row>
                <Button onClick={this.sendData}>Submit</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    );
  }

  AddNewField = () => {
    return (
      <>
        <this.alert />
        <Container fluid className="main-content-container px-4 pb-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="4"
              title="Create Form"
              subtitle="Add Form Fields"
              className="text-sm-left"
            />
          </Row>
          <Row>
            <Col md="6">
              <this.InputFields />
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return this.AddNewField();
  }
}
